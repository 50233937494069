.navigation-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 2rem;

  @media screen and (max-width: 550px) {
    // display: none;
    justify-content: space-evenly;
    align-content: stretch;
    padding: 0;
  }

  a,
  span {
    text-decoration: none;
    padding: 1.5rem 2rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1e1e1e;
    cursor: pointer;

    @media screen and (max-width: 488px) {
      padding: 1.5rem 0.8rem;
      font-size: 0.9rem;
    }
  }
}